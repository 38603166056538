import React from "react"
import config from "../../config"
import * as loginApiCalls from "../../login/framework/clients"
import { PaymentsClient } from "../../payments/framework/clients"
import * as planApiCalls from "../../plan/framework/clients"
import { Fetcher } from "./clients"

// TODO: unroll these clients into methods
const fetcher = typeof window === "undefined" ? new Fetcher({ fetch }, config.apiBaseUrl) : new Fetcher(window, config.apiBaseUrl)
const paymentsClient = new PaymentsClient(fetcher)
const planClient = new planApiCalls.PlanClient(fetcher)
const planUserClient = new planApiCalls.PlanUserClient(fetcher)
const planItemClient = new planApiCalls.PlanItemClient(fetcher)

export const context = {
  // TODO: maybe iterate through calls and construct them
  loginUser: loginApiCalls.loginUser(fetcher.fetch),
  registerUser: loginApiCalls.registerUser(fetcher.fetch),
  activateUser: loginApiCalls.activateUser(fetcher.fetch),
  refreshUser: loginApiCalls.refreshUser(fetcher.fetch),
  getUser: loginApiCalls.getUser(fetcher.fetch),
  getUserInvitation: loginApiCalls.getUserInvitation(fetcher.fetch),
  resetUserPassword: loginApiCalls.resetUserPassword(fetcher.fetch),
  newUserPassword: loginApiCalls.newUserPassword(
    fetcher.fetch,
  ),
  bulkInvitePlanUsers: planUserClient.bulkInvitePlanUsers,
  getPlans: planClient.getPlans,
  getPlanById: planClient.getById,
  createPlan: planClient.createPlan,
  archivePlan: planClient.archive,
  getCalendarsForPlan: planApiCalls.getCalendarsForPlan(fetcher.fetch),
  createCalendar: planApiCalls.createCalendar(fetcher.fetch),
  updateCalendar: planApiCalls.updateCalendar(fetcher.fetch),
  deleteCalendar: planApiCalls.deleteCalendar(fetcher.fetch),
  getEventsForCalendar: planApiCalls.getEventsForCalendar(fetcher.fetch),
  createEvent: planApiCalls.createEvent(fetcher.fetch),
  deleteEvent: planApiCalls.deleteEvent(fetcher.fetch),
  getItemsForPlan: planApiCalls.getItemsForPlan(fetcher.fetch),
  createItem: planItemClient.create,
  updateItem: planItemClient.update,
  deleteItem: planItemClient.delete,
  findLocationsForText: planApiCalls.findLocationsForText(fetcher.fetch),
  getTags: planApiCalls.getTags(fetcher.fetch),
  createTag: planApiCalls.createTag(fetcher.fetch),
  assignTag: planApiCalls.assignTag(fetcher.fetch),
  unassignTag: planApiCalls.unassignTag(fetcher.fetch),
  createComment: planApiCalls.createComment(fetcher.fetch),
  deleteComment: planApiCalls.deleteComment(fetcher.fetch),
  commitTo: planApiCalls.CommitTo(fetcher.fetch),
  unCommitTo: planApiCalls.UnCommitTo(fetcher.fetch),
  updatePlanInvite: planApiCalls.updatePlanInvite(fetcher.fetch),
  getPlanUsersForPlan: planApiCalls.getPlanUsersForPlan(fetcher.fetch),
  getExpensesForPlan: planApiCalls.getExpensesForPlan(fetcher.fetch),
  getExpenseForPlan: planApiCalls.getExpenseForPlan(fetcher.fetch),
  createExpense: planApiCalls.createExpense(fetcher.fetch),
  updateExpense: planApiCalls.updateExpense(fetcher.fetch),
  createDebtor: planApiCalls.createDebtor(fetcher.fetch),
  updateDebtor: planApiCalls.updateDebtor(fetcher.fetch),
  removeDebtor: planApiCalls.removeDebtor(fetcher.fetch),
  sendConfirmationEmail: loginApiCalls.sendConfirmationEmail(fetcher.fetch),
  resendInvitation: planApiCalls.resendInvitation(fetcher.fetch),
  createStripeIntent: paymentsClient.createStripeIntent,
  getBillingMethods: paymentsClient.getBillingMethods,
  createBillingMethod: paymentsClient.createBillingMethod,
  getPayoutMethods: paymentsClient.getPayoutMethods,
  createPayoutMethod: paymentsClient.createPayoutMethod,
  getCurrencies: paymentsClient.getCurrencies,
}
export const ApiContext = React.createContext(context)
