export const makeEvent = ({
  id,
  calendar,
  startTime,
  endTime,
  title,
  description,
}) => ({
  id,
  calendar,
  startTime,
  endTime,
  title,
  description,
})
