// decorator to attach a particular context to a thing

export const withContext =
  (Context, propName, selector = (v) => v) =>
  (Component) =>
  (props) =>
    (
      <Context.Consumer>
        {(ctxt) => <Component {...{ [propName]: selector(ctxt) }} {...props} />}
      </Context.Consumer>
    )
