export class PlanItem {
  constructor({
    id,
    title,
    location,
    description,
    planId,
    tags,
    commits,
    createdAt,
    updatedAt,
    deletedAt,
    comments
  }) {
    this.id = id
    this.title = title
    this.location = location
    this.description = description
    this.planId = planId
    this.tags = tags
    this.commits = commits
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.comments = comments
  }
  toJSON() {
    return {
      id: this.id,
      title: this.title,
      location: this.location == null ? null : this.location.toJSON(),
      description: this.description,
      planId: this.planId,
      tags: this.tags,
      commits: this.commits,
      comments: this.comments,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
    }
  }
  static fromJSON(json) {
    json.location = Location.fromJSON(json.location)
    return new PlanItem(json)
  }
}

export class Location {
  constructor({ latitude, longitude, address } = {}) {
    this.latitude = latitude
    this.longitude = longitude
    this.address = address
  }
  toJSON() { 
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      address: this.address,
    }
  }
  static fromJSON(json) {
    return new Location(json == null ? undefined : json)
  }
}
