import { actions } from "../../login/framework/actions"
import { refreshUser } from "../../login/framework/clients"
import { HTTPResponse } from "../domain/response"
import store from "../redux/store"

const DEFAULT_OPTIONS = {
  isMany: false,
  skipAuth: false,
  defaultJSON: true,
  doRefreshToken: true,
  expectEmpty: false,
}

class FetcherError extends Error {
  constructor(message) {
    super(message)
    this.name = 'FETCHER_ERROR'
    this.message = message
  }
}

export class Fetcher {
  constructor(fetcher, apiBaseUrl) {
    this.fetcher = fetcher
    this.apiBaseUrl = apiBaseUrl.endsWith("/") ? apiBaseUrl.slice(0, -1) : apiBaseUrl
    this.fetch = this.fetch.bind(this)
  }
  async fetch(request, options = DEFAULT_OPTIONS) {
    options = { ...DEFAULT_OPTIONS, ...options }
    const fallbackResponse = options.isMany ? [] : null
    let response
    const accessToken = localStorage.getItem("accessToken")
    const refreshToken = localStorage.getItem("refreshToken")

    const apiRequest = {
      ...request,
      headers: request.headers ?? {},
      url: `${this.apiBaseUrl}/${
        request.url.startsWith("/") ? request.url.slice(1) : request.url
      }`,
    }

    if (!options.skipAuth && accessToken) {
      apiRequest.headers["Authorization"] = `Bearer ${accessToken}`
    }
    if (options.defaultJSON) {
      apiRequest.headers["content-type"] = "application/json"
    }

    try {
      response = await this.fetcher.fetch(apiRequest.url, apiRequest)
    } catch (e) {
      return fallbackResponse
    }

    if (options.expectEmpty || response.status === 204) {
      return new HTTPResponse({
        status: response.status,
        jsonData: null,
      }).toJSON()
    }

    let responseJson = fallbackResponse
    try {
      responseJson = await response.json()
    } catch (e) {
      console.warn("Error parsing response:", e)
      throw new FetcherError(
        "Error parsing response or no item returned."
      )
    }

    if (
      (response.status === 401 || response.status === 403 || response.status === 404) &&
      accessToken &&
      refreshToken &&
      options.doRefreshToken
    ) {
      // Auth error. Seems like token is outdated.
      const response = await refreshUser(this.fetch)({ code: refreshToken })
      if (response.access) {
        localStorage.setItem("accessToken", response.access)
        return await this.fetch(request, { ...options, doRefreshToken: false })
      } else {
        store.dispatch(actions.doLogout())
        return new HTTPResponse({
          status: "error",
          jsonData: "You've been logged out.",
        }).toJSON()
      }
    } else if (response.status === 404) {
      return new HTTPResponse({ status: "error", error: "Not Found." }).toJSON()
    } else if (response.status > 400 && response.status !== 403) {
      throw new FetcherError( JSON.stringify(responseJson) )
    }

    return new HTTPResponse({
      status: response.status,
      jsonData: responseJson,
    }).toJSON()
  }
}


const encodeURIParts = (...parts) => parts.map(encodeURIComponent)

export class RESTClient {
  constructor(fetcher) {
    this.fetcher = fetcher
    this.query = this.query.bind(this)
    this.get = this.get.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
  }
  async query(resource, params = {}) {
    const request = {
      method: "GET",
      url: encodeURIParts(resource) + "?" + Object.entries(params).map(args => args.join("=")).join("&"),
      headers: {
        "Accept": "application/json",
      },
    }

    try {
      return await this.fetcher.fetch(request)
    } catch (e) {
      console.warn("Error parsing created plan item from response:", e)
      // TODO: even the errors return useful information - we should figure out how to expose that
      // there's some global-ish things like 401 ( should probably trigger an auth ), 500,  and some local things like 400, or 403
      //
      return null
    }
  }
  async get(resource, id) {
    const request = {
      method: "GET",
      url: encodeURIParts(resource, id),
      headers: {
        "Accept": "application/json",
      },
    }

    try {
      return await this.fetcher.fetch(request)
    } catch (e) {
      console.warn(`Error parsing ${resource} from response`, e)
      return null
    }
  }
  async create(resource, data) {
    const request = {
      method: "POST",
      url: encodeURIParts(resource),
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    }

    try {
      return await this.fetcher.fetch(request)
    } catch (e) {
      console.warn(`Error parsing ${resource} from response`, e)
      return null
    }
  }

  async update(resource, data, options = {}) {
    const request = {
      method: options.replace ? "PUT" : "PATCH",
      url: encodeURIParts(resource, data.id),
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    }

    try {
      return await this.fetcher.fetch(request)
    } catch (e) {
      console.warn(`Error parsing ${resource} from response`, e)
      return null
    }
  }

  async delete(resource, id) {
    const request = {
      method: "DELETE",
      url: encodeURIParts(resource, id),
    }
    return await this.fetcher.fetch(request)
  }
}

// const client = new RESTClient()
// client.query("books", { title: "Sherlock" })

// interesting Idea - queryset / orm like possible?
// client.query("books", { title: "=Sherlock" }).update({ title: "Sherlock Holmes"}) // issues bulk update
// client.query("books", { title: "=Sherlock" }).delete() // issues bulk delete