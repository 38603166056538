export class HTTPResponse {
  constructor({ status, jsonData }) {
    this.status = status
    this.jsonData = jsonData
  }

  error() {
    return this.status === "error" || this.status >= 400
  }

  toJSON() {
    return this.error() ? { error: true, ...this.jsonData } : this.jsonData
  }
}
