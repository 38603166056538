import { User } from "../../login/domain/user"

export class PlanUser {
  constructor({ id, user, plan, type, is_accepted, created_at }) {
    this.id = id
    this.planId = plan
    this.user = new User(user)
    this.type = type
    this.isAccepted = is_accepted
    this.createdAt = created_at
  }
  toJSON() {
    return {
      id: this.id,
      planId: this.planId,
      type: this.type,
      user: this.user.toJSON(),
      isAccepted: this.isAccepted,
      createdAt: this.createdAt,
    }
  }
  static fromJSON(json) {
    return new PlanUser(json)
  }
}
