export const loginUser = (fetch) => (credentials) =>
  fetch({
    method: "POST",
    url: "/auth/login/",
    body: JSON.stringify(credentials),
    headers: {
      "content-type": "application/json",
    },
  })

export const registerUser =
  (fetch) =>
  ({ userData }) =>
    fetch({
      method: "POST",
      url: "/auth/user-register/",
      body: JSON.stringify(userData),
      headers: {
        "content-type": "application/json",
      },
    })

export const activateUser =
  (fetch) =>
  ({ refresh }) =>
    fetch({
      method: "POST",
      url: "/auth/token-refresh/",
      body: JSON.stringify({ refresh }),
      headers: {
        "content-type": "application/json",
      },
    })

export const refreshUser =
  (fetch) =>
  ({ code }) =>
    fetch(
      {
        method: "PATCH",
        url: `/auth/user-activate/${code}/`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          is_active: true,
        }),
      },
      {
        doRefreshToken: false,
      }
    )

export const getUser = (fetch) => () =>
  fetch({
    method: "GET",
    url: "/auth/user/",
    headers: {
      "content-type": "application/json",
    },
  })

export const getUserInvitation =
  (fetch) =>
  ({ invitationCode }) =>
    fetch({
      method: "GET",
      url: `/auth/user-invitation/${invitationCode}/`,
      headers: {
        "content-type": "application/json",
      },
    })

export const resetUserPassword = (fetch) => (email) =>
  fetch({
    method: "POST",
    url: "/auth/password-reset/",
    body: JSON.stringify({ email }),
    headers: {
      "content-type": "application/json",
    },
  })

export const newUserPassword =
  (fetch) =>
  async ({ token, password }) => {
    const result = await fetch({
      method: "POST",
      url: "/auth/password-reset/confirm/",
      body: JSON.stringify({ token, password }),
      headers: {
        "content-type": "application/json",
      },
    })

    if (result.error && !result.password) {
      throw new Error("Invalid token. Please check the link in your email and try again.")
    }
    return result
  }

export const sendConfirmationEmail = (fetch) => (email) =>
  fetch({
    method: "POST",
    url: `/auth/resend-confirmation-email/`,
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
