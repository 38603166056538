export class PaymentsClient {
  constructor(fetcher) {
    this.fetcher = fetcher
    this.createStripeIntent = this.createStripeIntent.bind(this)
    this.getBillingMethods = this.getBillingMethods.bind(this)
    this.createBillingMethod = this.createBillingMethod.bind(this)
    this.getPayoutMethods = this.getPayoutMethods.bind(this)
    this.createPayoutMethod = this.createPayoutMethod.bind(this)
    this.getCurrencies = this.getCurrencies.bind(this)
  }

  async createStripeIntent() {
    const request = {
      method: "POST",
      url: "/payments/stripe-intent-create/",
      headers: {
        "content-type": "application/json",
      },
    }

    return await this.fetcher.fetch(request)
  }

  async getBillingMethods() {
    const request = {
      method: "GET",
      url: "/payments/billing-methods/",
      headers: {
        "content-type": "application/json",
      },
    }
    return await this.fetcher.fetch(request)
  }

  async createBillingMethod(method) {
    const request = {
      method: "POST",
      url: "/payments/billing-methods/",
      body: JSON.stringify(method),
      headers: {
        "content-type": "application/json",
      },
    }
    return await this.fetcher.fetch(request)
  }

  async getPayoutMethods() {
    const request = {
      method: "GET",
      url: "/payments/payout-methods/",
      headers: {
        "content-type": "application/json",
      },
    }
    return await this.fetcher.fetch(request)
  }

  async getCurrencies() {
    const request = {
      method: "GET",
      url: "/payments/currencies/",
      headers: {
        "content-type": "application/json",
      },
    }
    return await this.fetcher.fetch(request)
  }

  async createPayoutMethod(method) {
    const request = {
      method: "POST",
      url: "/payments/payout-methods/",
      body: JSON.stringify(method),
      headers: {
        "content-type": "application/json",
      },
    }
    return await this.fetcher.fetch(request)
  }
}
