export const makeCalendar  = ({ id, plan, title, is_default }) => {
return {        
        id,
        planId : plan,
        title,
        isDefault : is_default
    }
}


