export class Plan {
  constructor({
    id,
    title,
    location,
    period,
    createdAt,
    updatedAt,
    deletedAt,
    participationType,
    participationAccepted,
    archived,
  }) {
    this.id = id
    this.title = title
    this.location = location
    this.period = period
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.participationType = participationType
    this.participationAccepted = participationAccepted
    this.archived = archived
  }

  markDeleted(atDate) {
    this.deletedAt = atDate == null ? new Date() : atDate
    return this
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      location: this.location,
      period: this.period,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt,
      participationType: this.participationType,
      participationAccepted: this.participationAccepted,
      archived: this.archived,
    }
  }

  static fromJSON(json) {
    return new Plan(json)
  }
}
