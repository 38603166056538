import { makeCalendar } from "../domain/calendar"
import { makeComment } from "../domain/comment"
import { makeCommit } from "../domain/commit"
import { makeEvent } from "../domain/event"
import {
  SPLIT_STRATEGIES,
  getSplitStrategyId,
  makeDebtor,
  makeExpense,
} from "../domain/expense"
import { Plan } from "../domain/plan"
import { Location, PlanItem } from "../domain/planItem"
import { PlanUser } from "../domain/planUser"
import { makeTag } from "../domain/tag"

export const findLocationsForText = (fetch) => async (textQuery) => {
  if (textQuery === "" || textQuery == null) {
    return []
  }
  const request = {
    method: "GET",
    url: `/plan/locations/?query=${encodeURIComponent(textQuery)}`,
  }
  let results = await fetch(request, { isMany: true })
  try {
    return results.map(Location.fromJSON)
  } catch (exception) {
    console.warn("Error parsing locations from response:", exception)
    return []
  }
}

export class PlanClient {
  constructor(fetcher) {
    this.fetcher = fetcher
    this.getPlans = this.getPlans.bind(this)
    this.getById = this.getById.bind(this)
    this.createPlan = this.createPlan.bind(this)
    this.archive = this.archive.bind(this)
  }
  static deserializePlan(json) {
    return new Plan({
      id: json.id,
      title: json.title,
      createdAt: json.created_at ? new Date(json.created_at) : undefined,
      updatedAt: json.updated_at ? new Date(json.updated_at) : undefined,
      participationType: json.participation_type,
      participationAccepted: json.participation_accepted,
      archived: json.archived,
    })
  }
  static serializePlan(plan) {
    return {
      id: plan.id,
      title: plan.title,
      created_at: plan.createdAt ? plan.createdAt.toUTCString() : undefined,
      updated_at: plan.updatedAt ? plan.createdAt.toUTCString() : undefined,
    }
  }
  async getPlans() {
    const request = {
      method: "GET",
      url: `/plan/plans/`,
    }

    const results = await this.fetcher.fetch(request, { isMany: true })
    try {
      return results.map(PlanClient.deserializePlan)
    } catch (exception) {
      console.warn("Error parsing plans from response:", exception)
      return []
    }
  }

  async getById(planId) {
    const request = {
      method: "GET",
      url: `/plan/plans/${encodeURIComponent(planId)}/`,
    }

    const result = await this.fetcher.fetch(request, { isMany: true })
    try {
      return PlanClient.deserializePlan(result)
    } catch (exception) {
      console.warn("Error parsing plan from response:", exception)
      return []
    }
  }
  async createPlan(plan) {
    const request = {
      method: "POST",
      url: `/plan/plans/`,
      body: JSON.stringify(plan.toJSON()),
      headers: {
        "content-type": "application/json",
      },
    }
    const results = await this.fetcher.fetch(request)
    try {
      return PlanClient.deserializePlan(results)
    } catch (e) {
      console.warn("Error parsing created plan from response:", e)
      return null
    }
  }
  async archive(planId) {
    const request = {
      method: "DELETE",
      url: `/plan/plans/${encodeURIComponent(planId)}/`,
    }

    await this.fetcher.fetch(request)
  }
}

const deserializePlanItem = (json) => {
  return new PlanItem({
    id: json.id,
    title: json.title,
    planId: json.plan,
    description: json.description,
    tags: json.tags ? json.tags.map(normalizeTag) : [],
    commits: json.commits ? json.commits.map(normalizeCommit) : [],
    comments: json.comments ? json.comments.map(normalizeComment) : [],
    createdAt: json.created_at ? new Date(json.created_at) : undefined,
    updatedAt: json.updated_at ? new Date(json.updated_at) : undefined,
    location: json.location,
  })
}

export class PlanItemClient {
  constructor(fetcher) {
    this.fetcher = fetcher
    this.getPlanItemsForPlan = this.getPlanItemsForPlan.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
  }
  static deserializePlanItem(json) {
    return deserializePlanItem(json)
  }
  static serializePlanItem(planItem) {
    return {
      id: planItem.id,
      title: planItem.title,
      plan: planItem.planId,
      description: planItem.description,
      created_at: planItem.createdAt,
      updated_at: planItem.updatedAt,
      location: planItem.location,
    }
  }
  async getPlanItemsForPlan(planId) {
    return getItemsForPlan(this.fetcher.fetch)(planId)
  }
  async create(planItem) {
    const request = {
      method: "POST",
      url: `/plan/${encodeURIComponent(planItem.planId)}/plan-items/`,
      body: JSON.stringify(PlanItemClient.serializePlanItem(planItem.toJSON())),
      headers: {
        "content-type": "application/json",
      },
    }

    const results = await this.fetcher.fetch(request)
    try {
      return PlanItemClient.deserializePlanItem(results)
    } catch (e) {
      console.warn("Error parsing created plan item from response:", e)
      return null
    }
  }
  async update(planItem) {
    const request = {
      method: "PATCH",
      url: `/plan/${encodeURIComponent(
        planItem.planId
      )}/plan-items/${encodeURIComponent(planItem.id)}/`,
      body: JSON.stringify(PlanItemClient.serializePlanItem(planItem)),
      headers: {
        "content-type": "application/json",
      },
    }

    const results = await this.fetcher.fetch(request)
    try {
      return PlanItemClient.deserializePlanItem(results)
    } catch (e) {
      console.warn("Error parsing updated plan item from response:", e)
      return null
    }
  }

  async delete(id, plan) {
    const request = {
      method: "DELETE",
      url: `/plan/${encodeURIComponent(plan)}/plan-items/${encodeURIComponent(
        id
      )}/`,
    }

    await this.fetcher.fetch(request)
  }
}

export const getItemsForPlan = (fetch) => async (planId) => {
  const results = await fetch(
    {
      method: "GET",
      url: `/plan/${encodeURIComponent(planId)}/plan-items/`,
    },
    { isMany: true }
  )
  try {
    return results.map(deserializePlanItem)
  } catch (exception) {
    console.warn("Error parsing plan items from response:", exception)
    return []
  }
}

export const deserializePlanUser = (json) => new PlanUser(json)

export const serializePlanUser = (planUser) => ({
  plan: planUser.planId,
})

export class PlanUserClient {
  constructor(fetcher) {
    this.fetcher = fetcher
    this.getPlanUsersForPlan = this.getPlanUsersForPlan.bind(this)
    this.createPlanUser = this.createPlanUser.bind(this)
    this.bulkInvitePlanUsers = this.bulkInvitePlanUsers.bind(this)
    this.update = this.update.bind(this)
    this.updatePlanInvite = this.updatePlanInvite.bind(this)
  }
  static deserializePlanUser(json) {
    return deserializePlanUser(json)
  }
  static serializePlanUser(planUser) {
    return serializePlanUser(planUser)
  }
  async getPlanUsersForPlan(planId) {
    return getPlanUsersForPlan(this.fetcher.fetch)(planId)
  }

  async createPlanUser(planUser) {
    const request = {
      method: "POST",
      url: `/plan/${planUser.planId}/plan-users/`,
      body: JSON.stringify({
        user_email: planUser.emails,
        type: planUser.type,
        plan: planUser.planId,
      }),
      headers: {
        "content-type": "application/json",
      },
    }

    const results = await this.fetcher.fetch(request)
    try {
      return PlanUserClient.deserializePlanUser(results)
    } catch (e) {
      console.warn("Error parsing created plan user from response:", e)
      return null
    }
  }

  async bulkInvitePlanUsers(planInvite) {
    const request = {
      method: "POST",
      url: `/plan/${planInvite.planId}/send-bulk-invites`,
      body: JSON.stringify({
        plan: planInvite.planId,
        user_emails: planInvite.emails,
      }),
      headers: {
        "content-type": "application/json",
      },
    }
    const results = await this.fetcher.fetch(request)
    try {
      return results.map(PlanUserClient.deserializePlanUser)
    } catch (e) {
      console.warn("Error parsing created plan user from response:", e)
      return null
    }
  }

  async update(planUser) {
    const request = {
      method: "PUT",
      url: `/plan/${encodeURIComponent(
        planUser.planId
      )}/plan-users/${encodeURIComponent(planUser.id)}/`,
      body: JSON.stringify(PlanUserClient.serializePlanUser(planUser.toJSON())),
      headers: {
        "content-type": "application/json",
      },
    }

    const results = await this.fetcher.fetch(request)
    try {
      return PlanUserClient.deserializePlanUser(results)
    } catch (e) {
      console.warn("Error parsing updated plan user from response:", e)
      return null
    }
  }
  async updatePlanInvite(planUser) {
    return updatePlanInvite(this.fetcher.fetch)(planUser)
  }
}

export const getPlanUsersForPlan = (fetch) => async (planId) => {
  const request = {
    method: "GET",
    url: `/plan/${encodeURIComponent(planId)}/plan-users/`,
  }

  const results = await fetch(request, { isMany: true })
  try {
    return results.map(deserializePlanUser)
  } catch (exception) {
    console.warn("Error parsing plan users from response:", exception)
    return []
  }
}

export const updatePlanInvite = (fetch) => async (planUser) => {
  const results = await fetch({
    method: "PATCH",
    url: `/plan/${encodeURIComponent(
      planUser.plan
    )}/plan-users/${encodeURIComponent(planUser.planUserId)}/`,
    body: JSON.stringify({
      is_accepted: planUser.isAccepted,
    }),
    headers: {
      "content-type": "application/json",
    },
  })
  try {
    return deserializePlanUser(results)
  } catch (e) {
    console.warn("Error parsing update to plan from response:", e)
    return null
  }
}

export const normalizeExpense = (json) =>
  makeExpense({
    id: json.id,
    creditorId: json.creditor.id,
    amount: json.amount,
    currency: json.amount_currency,
    occurredAt: json.occurred_at,
    splitStrategy: SPLIT_STRATEGIES[json.split_strategy],
    comment: json.comment,
    planId: json.plan,
    planItemId: json.plan_item,
    debtors: json.debtors.map((debtor) =>
      makeDebtor({
        expenseId: json.id,
        userId: debtor.user.id,
        amount: debtor.amount,
        currency: debtor.amount_currency,
        settledAt: debtor.settled_at,
      })
    ),
  })

const denormalizeExpense = (expense) => {
  const body = {
    id: expense.id,
    plan: expense.planId,
    creditor: expense.creditor,
    amount: expense.amount,
    amount_currency: expense.currency,
    is_accepted: expense.isAccepted,
    status: expense.status,
    comment: expense.comment,
    occurred_at: expense.occurredAt,
    split_strategy: getSplitStrategyId(expense.splitStrategy),
    debtors: (expense.debtors || []).map(denormalizeDebtor(expense)),
  }
  if (expense.planItemId) {
    body.plan_item_id = expense.planItemId
  }
  return body
}

const denormalizeDebtor = (expense) => (debtor) => ({
  expense_id: expense.id,
  is_accepted: debtor.isAccepted,
  settled_at: debtor.settledAt,
  user_id: debtor.userId,
  amount: debtor.amount,
  amount_currency: debtor.currency,
})

export const getExpensesForPlan = (fetch) => async (planId) =>
  fetch(
    {
      method: "GET",
      url: `/plan/${encodeURIComponent(planId)}/plan-expenses/`,
    },
    { isMany: true }
  ).then((result) => result.map(normalizeExpense))

// TODO: move `planId` from here to use case / elsewhere
export const getExpenseForPlan = (fetch) => async (planId, expenseId) =>
  fetch({
    method: "GET",
    url: `/plan/${encodeURIComponent(
      planId
    )}/plan-expenses/${encodeURIComponent(expenseId)}/`,
  }).then(normalizeExpense)

export const updateExpense = (fetch) => async (expense) => {
  const response = await fetch({
    method: "PATCH",
    url: `/plan/${encodeURIComponent(
      expense.planId
    )}/plan-expenses/${encodeURIComponent(expense.id)}/`,
    body: JSON.stringify(denormalizeExpense(expense)),
  })

  if (response.error) {
    return response
  }

  try {
    return normalizeExpense(response)
  } catch (e) {
    console.warn("Error parsing updated expense from response:", e)
    return null
  }
}

export const createExpense = (fetch) => async (expense) => {
  const response = await fetch({
    method: "POST",
    url: `/plan/${expense.planId}/plan-expenses/`,
    body: JSON.stringify(denormalizeExpense(expense)),
  })

  if (response.error) {
    return response
  }

  try {
    return normalizeExpense(response)
  } catch (e) {
    console.warn("Error parsing created plan expense from response:", e)
    return { error: true, amount: "Unknown Error." }
  }
}

const objToQueryParams = (obj) =>
  Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join("&")

export const createDebtor = (fetch) => async (planId, debtor) =>
  fetch({
    method: "POST",
    url: `/plan/${planId}/debts/?${objToQueryParams({
      expense_id: debtor.expenseId,
    })}`,
    body: JSON.stringify(denormalizeDebtor({ id: debtor.expenseId })(debtor)),
  })

export const updateDebtor = (fetch) => async (planId, debtor) =>
  fetch({
    method: "PATCH",
    url: `/plan/${planId}/debts/?${objToQueryParams({
      expense_id: debtor.expenseId,
    })}`,
    body: JSON.stringify(denormalizeDebtor({ id: debtor.expenseId })(debtor)),
  })

// TODO: hacky putting in an empty id
export const removeDebtor = (fetch) => async (planId, debtor) =>
  fetch(
    {
      method: "DELETE",
      url: `/plan/${planId}/debts/${debtor.id}/?${objToQueryParams({
        expense_id: debtor.expenseId,
        user_id: debtor.userId,
      })}`,
    },
    { expectEmpty: true }
  )

export const getCalendarsForPlan = (fetch) => async (planId) => {
  const request = {
    method: "GET",
    url: `/plan/${encodeURIComponent(planId)}/calendars/`,
  }
  const results = await fetch(request, { isMany: true })
  try {
    return results.map(makeCalendar)
  } catch (exception) {
    console.warn("Error parsing plan calendars from response:", exception)
    return []
  }
}

export const createCalendar = (fetch) => async (planId, title) => {
  const request = {
    method: "POST",
    url: `/plan/${encodeURIComponent(planId)}/calendars/`,
    body: JSON.stringify(denormalizeCalendar(planId, title)),
    headers: {
      "content-type": "application/json",
    },
  }

  const results = await fetch(request, { isMany: false })
  try {
    return makeCalendar(results)
  } catch (exception) {
    console.warn("Error parsing plan calendars from response:", exception)
    return null
  }
}

export const updateCalendar = (fetch) => async (update) => {
  const request = {
    method: "PATCH",
    url: `/plan/${encodeURIComponent(
      update.plan
    )}/calendars/${encodeURIComponent(update.id)}/`,
    body: JSON.stringify(update),
    headers: {
      "content-type": "application/json",
    },
  }

  const results = await fetch(request, { isMany: false })

  try {
    return makeCalendar(results)
  } catch (exception) {
    console.warn("Error parsing plan calendars from response:", exception)
    return null
  }
}

export const deleteCalendar = (fetch) => async (calendarId, planId) => {
  const request = {
    method: "DELETE",
    url: `/plan/${encodeURIComponent(planId)}/calendars/${encodeURIComponent(
      calendarId
    )}/`,
    headers: {
      "content-type": "application/json",
    },
  }

  const results = await fetch(request, { isMany: false })
  try {
    return results
  } catch (exception) {
    console.warn("Error parsing plan calendars from response:", exception)
    return null
  }
}

const denormalizeCalendar = (planId, title) => ({
  plan: planId,
  title: title,
})

const denormalizeEvent = (event) => ({
  calendar: event.calendar,
  start_time: event.startTime.toISOString(),
  end_time: event.endTime.toISOString(),
  title: event.title,
})

const normalizeEvent = (serializedEvent) =>
  makeEvent({
    id: serializedEvent.id,
    calendar: serializedEvent.calendar,
    startTime: new Date(serializedEvent.start_time),
    endTime: new Date(serializedEvent.end_time),
    title: serializedEvent.title,
    description: serializedEvent.description,
  })

export const createEvent = (fetch) => async (event, planId) => {
  const request = {
    method: "POST",
    url: `/plan/${encodeURIComponent(planId)}/events/`,
    body: JSON.stringify(denormalizeEvent(event)),
    headers: {
      "content-type": "application/json",
    },
  }

  const response = await fetch(request)

  if (response.error) {
    return response
  }

  try {
    return normalizeEvent(response)
  } catch (exception) {
    console.warn("Error parsing calendar events from response:", exception)
    return {}
  }
}

export const getEventsForCalendar = (fetch) => async (planId, calendarId) => {
  let query = calendarId ? `?calendar=${encodeURIComponent(calendarId)}` : ""

  const request = {
    method: "GET",
    url: `/plan/${encodeURIComponent(planId)}/events/${query}`,
    headers: {
      "content-type": "application/json",
    },
  }
  const results = await fetch(request, { isMany: true })
  try {
    return results.map(normalizeEvent)
  } catch (exception) {
    console.warn("Error parsing calendar events from response:", exception)
    return []
  }
}

export const deleteEvent = (fetch) => async (eventId, planId) => {
  const request = {
    method: "DELETE",
    url: `/plan/${encodeURIComponent(planId)}/events/${encodeURIComponent(eventId)}/`,
    headers: {
      "content-type": "application/json",
    },
  }
  const result = await fetch(request, { isMany: false })
  try {
    return [normalizeEvent(result)]
  } catch (exception) {
    console.warn("Error parsing calendar events from response:", exception)
    return []
  }
}

const normalizeTag = (serializedTag) =>
  makeTag({
    id: serializedTag.id,
    name: serializedTag.name,
    planId: serializedTag.plan,
    createdAt: serializedTag.created_at,
  })
const denormalizeTag = (tag) => ({
  id: tag.id,
  name: tag.name,
  plan: tag.planId,
  created_at: tag.createdAt,
})

export const getTags = (fetch) => async (planId) =>
  fetch(
    {
      method: "GET",
      url: `/plan/${encodeURIComponent(planId)}/tags/`,
    },
    { isMany: true }
  ).then((result) => result.map(normalizeTag))

export const createTag = (fetch) => async (tag) =>
  fetch({
    method: "POST",
    url: `/plan/${encodeURIComponent(tag.planId)}/tags/`,
    body: JSON.stringify(denormalizeTag(tag)),
  }).then(normalizeTag)

export const assignTag =
  (fetch) =>
  async ({ item, tag }) =>
    fetch({
      method: "POST",
      url: `/plan/${encodeURIComponent(item.planId)}/associate-tag`,
      body: JSON.stringify({ item: item.id, tag: tag.id }),
    }).then(({ item }) => PlanItemClient.deserializePlanItem(item))

export const unassignTag =
  (fetch) =>
  async ({ item, tag }) =>
    fetch({
      method: "DELETE",
      url: `/plan/${encodeURIComponent(item.planId)}/associate-tag`,
      body: JSON.stringify({ item: item.id, tag: tag.id }),
    })

export const resendInvitation =
  (fetch) =>
  async ({ planId, email }) =>
    fetch({
      method: "POST",
      url: `/plan/${encodeURIComponent(planId)}/manage-invite`,
      body: JSON.stringify({ email }),
    })

const normalizeCommit = (serializedCommit) =>
  makeCommit({
    id: serializedCommit.id,
    userId: serializedCommit.user,
    planItemId: serializedCommit.plan_item,
    createdAt: serializedCommit.created,
  })

export const CommitTo =
  (fetch) =>
  async ({ itemId, planId }) =>
    fetch({
      method: "POST",
      url: `/plan/${encodeURIComponent(planId)}/plan-items/${encodeURIComponent(
        itemId
      )}/commit`,
    }).then(PlanItemClient.deserializePlanItem)

export const UnCommitTo =
  (fetch) =>
  async ({ itemId, planId }) =>
    fetch({
      method: "DELETE",
      url: `/plan/${encodeURIComponent(planId)}/plan-items/${encodeURIComponent(
        itemId
      )}/commit`,
    }).then(PlanItemClient.deserializePlanItem)

const normalizeComment = (serializedComment) =>
  makeComment({
    id: serializedComment.id,
    userId: serializedComment.user.id,
    planItemId: serializedComment.plan_item,
    body: serializedComment.body,
    createdAt: serializedComment.created_at,
  })

export const createComment =
  (fetch) =>
  async ({ planId, itemId, body }) =>
    fetch({
      method: "POST",
      url: `/plan/${encodeURIComponent(planId)}/plan-items/${encodeURIComponent(
        itemId
      )}/comments/`,
      body: JSON.stringify({
        plan_item: itemId,
        body,
      }),
    }).then(normalizeComment)

export const deleteComment =
  (fetch) =>
  async ({ planId, planItemId, id }) =>
    fetch(
      {
        method: "DELETE",
        url: `/plan/${encodeURIComponent(
          planId
        )}/plan-items/${encodeURIComponent(
          planItemId
        )}/comments/${encodeURIComponent(id)}/`,
      },
      { expectEmpty: true }
    )

export const getCommentsForItem =
  (fetch) =>
  async ({ planId, itemId }) =>
    fetch(
      {
        method: "GET",
        url: `/plan/${encodeURIComponent(
          planId
        )}/plan-items/${encodeURIComponent(itemId)}/comments/`,
      },
      { isMany: true }
    ).then(normalizeComment)
