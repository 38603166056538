import _ from "lodash/fp"
export const SPLIT_STRATEGIES = {
  0: "EQUAL",
  1: "EXPLICIT",
}

export const getSplitStrategyId = _.flow(
  (searchLabel) => ([id, label]) => label === searchLabel,
  (predicate) => Object.entries(SPLIT_STRATEGIES).find(predicate),
  _.first
)

export const makeExpense = ({
  id,
  comment,
  occurredAt,
  amount,
  currency,
  creditorId,
  splitStrategy,
  planId,
  planItemId,
  debtors,
}) => ({
  id,
  comment,
  occurredAt,
  amount,
  currency,
  creditorId,
  splitStrategy,
  planId,
  planItemId,
  debtors,
})

export const makeDebtor = ({
  id,
  expenseId,
  amount,
  currency,
  userId,
  settledAt,
}) => ({
  id,
  expenseId,
  userId,
  amount,
  currency,
  settledAt,
})
