// import "./container.css"
import classNames from "classnames"
import React from "react"

export const Container = (props) => (
  <div
    className={classNames(
      "container",
      "max-w-lg",
      "mx-auto",
      "py-4",
      props.className
    )}
    {...props}
  />
)

export const Inset = (props) => <div className="px-4 md:px-0" {...props} />

export default Container
